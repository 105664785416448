import React from "react"
import Layout from "../../layouts/index"

export default () => (
  <Layout path="pd">
    <div className="pd-hero auto">
      <div className="content-wrap">
        <div className="pd-top-info auto">
          <div className="pd-name">
            <div className="pd-logo"></div>
            <div className="title">
              <h2>Luma自动化</h2>
              <span>图形化的工作流设计工具</span>
            </div>
          </div>
          <h4>在Luma的帮助下，自动化更加容易了</h4>
          <p>
            Luma自动化引擎包含了图形化的工作流设计工具。您可以只写少量代码甚至无需编写代码来创建您的自动化服务，从而解放IT部门和分析师，使他们可以专注在高价值的业务上。
          </p>
          <p>
            Luma包含了大量开箱即用的任务模块，涵盖员工入职、密码重置以及基础设施的监控修复等，您可以通过简单的配置部署适合行业的个性化方案。
          </p>
        </div>
      </div>
    </div>
    <div className="pd-section pd-auto-section1">
      <div className="content-wrap">
        <div className="pd-auto-sec1-detail">
          <div className="pd-detail-text">
            <h3>仿佛置身自动驾驶舱的体验</h3>
            <h4>全面解放工作效率</h4>
            <p>
              Luma自动化引擎集成领先的服务管理方案，一套系统完成IT任务和服务自动化管理。
            </p>
            <p>
              事实上，70%的客服和IT任务可以实现自动化Luma致力于帮助您解放员工效率，使员工可以更加专注在富有战略意义的事务上，享受工作。
            </p>
            <p>
              Luma可以给您带来成本的降低，并优化工作效率、可用性和性能，最终实现最佳的最终用户满意度和体验。
            </p>
            <p>Luma自动化引擎将使您的IT和企业服务管理更加现代化。</p>
          </div>
        </div>
      </div>
    </div>
    <div className="pd-section">
      <div className="content-wrap">
        <h3>基于人工智能的变革性技术</h3>
        <h4>
          想象您的最终用户与虚拟支持助理讨论他们的服务需求，
          <br />
          并且他们的请求立即使用自动化响应完成。
        </h4>
        <h5>
          这就是<span>Luma人工智能虚拟支持助理 + Luma自动化引擎</span>的力量。
          <br />
          用户无法寻找到他们想要的信息，以及试图填写复杂表单的日子已经一去不复返了。
          <br />
          即刻开始，切换您的服务管理——从竖井式管理到水平管理，并引导用户通过智能客服获取信息。
          <br />
          Luma基于人工智能技术，理解用户需求，并实现完全的智能自助服务。
          <br />
          了解更多信息，拥抱人工智能，请即刻联系ServiceAI。
        </h5>
        <div className="pd-auto-section2"></div>
      </div>
    </div>
    <div className="pd-section">
      <div className="content-wrap">
        <h3>Luma自动化引擎工作流</h3>
        <ul className="pd-auto-grid">
          <li>
            <h4>密码重置</h4>
            <p>推送通知用户触发密码重置</p>
          </li>
          <li>
            <h4>短信提醒</h4>
            <p>向业务人员提供关键问题或警报的短信</p>
          </li>
          <li>
            <h4>事件管理</h4>
            <p>ISM中的事件捕获，创建工单</p>
          </li>
          <li>
            <h4>账户解锁</h4>
            <p>推送完成账户解锁</p>
          </li>
          <li>
            <h4>邮件提醒</h4>
            <p>向业务人员提供关于关键问题或警报的电子邮件</p>
          </li>
          <li>
            <h4>Salesforce联系人E-bonding</h4>
            <p>单摘要推送给Salesforce联系人</p>
          </li>
          <li>
            <h4>用户封禁</h4>
            <p>推送用户封禁信息至分</p>
          </li>
          <li>
            <h4>工单E-bonding</h4>
            <p>ISM中的新工单流程，实现双向管理直至关闭</p>
          </li>
          <li>
            <h4>Salesforce授权E-bonding</h4>
            <p>可实现从Salesforce到ISM的客户信息同步授权</p>
          </li>
        </ul>
      </div>
    </div>
    <div className="pd-section">
      <div className="content-wrap">
        <h3>查看和配置适用于您的自动化工作流和集成环境</h3>
        <h5>
          Luma不仅仅是一个智能聊天机器人。它还包括一个自动化引擎，它可以在没有分析师参与的情况下实现许多智能客服工作流。
          <br />
          <br />
          Luma直观的用户界面使客服系统管理员能够直观、快速地配置自动化工作流和查看集成状态。
          <br />
          Luma预设了超过25个开箱即用的工作流，并持续更新。
        </h5>
        <div className="pd-auto-connects"></div>
      </div>
    </div>
  </Layout>
)
